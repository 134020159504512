import classNames from 'classnames';
import React, { FC } from 'react';
import Spinner from '../Spinner/Spinner';
import styles from './Button.module.sass';

type Variant = 'grey';

interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  children?: React.ReactNode;
  loading?: boolean;
  variant?: Variant | null;
}

const Button: FC<ButtonProps> = ({
  children,
  className,
  loading,
  variant,
  disabled,
  ...rest
}) => {
  return (
    <button
      {...rest}
      className={classNames(className, styles.button, {
        [styles['button--loading']]: loading,
        [styles['button--grey']]: variant === 'grey',
        [styles['button--disabled']]: disabled,
      })}
      disabled={disabled}
    >
      {children}
      {loading && <Spinner className={styles.button__spinner} />}
    </button>
  );
};

export default Button;
