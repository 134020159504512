import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface AppState {
  isFetchErorPopupActive: boolean;
  isUnsendedActionsPopupActive: boolean;
}

const initialState = {
  isFetchErorPopupActive: false,
  isUnsendedActionsPopupActive: false,
} as AppState;

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleFetchErrorPopup(state, action: PayloadAction<boolean>) {
      state.isFetchErorPopupActive = action.payload;
    },
    toggleUnsendedActionsPopup(state, action: PayloadAction<boolean>) {
      state.isUnsendedActionsPopupActive = action.payload;
    },
  },
});

export const { toggleFetchErrorPopup, toggleUnsendedActionsPopup } =
  appSlice.actions;
export default appSlice.reducer;
