import {
  ActionDownloadPayload,
  ActionDownloadResponse,
  ActionsListPayload,
  ActionsListResponse,
  AddInsuredsPayload,
  AddInsuredsResponse,
  DeleteActionPayload,
  DeleteActionResponse,
  DeleteInsuredsPayload,
  DeleteInsuredsResponse,
  EditInsuredsPayload,
  EditInsuredsProgramsPayload,
  EditInsuredsProgramsResponse,
  EditInsuredsResponse,
  ExportPayload,
  ExportResponse,
  SendActionsPayload,
  SendActionsResponse,
  SendEmailDocPayload,
  SendEmailDocResponse,
} from './actionsApi.interface';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiUrl } from '../../consts';
import { getFormData } from '../../utils/getFormData';

export const actionsApi = createApi({
  reducerPath: 'actionsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl,
  }),
  tagTypes: ['Actions'],
  endpoints: (builder) => ({
    // Добавить застрахованных
    addInsureds: builder.mutation<AddInsuredsResponse, AddInsuredsPayload>({
      query: (payload) => ({
        url: 'actions/add_insureds',
        method: 'POST',
        body: getFormData(payload),
      }),
      invalidatesTags: [{ type: 'Actions', id: 'LIST' }],
    }),
    // Удалить застрахованных
    deleteInsureds: builder.mutation<
      DeleteInsuredsResponse,
      DeleteInsuredsPayload
    >({
      query: (payload) => ({
        url: 'actions/delete_insureds',
        method: 'POST',
        body: getFormData(payload),
      }),
      invalidatesTags: [{ type: 'Actions', id: 'LIST' }],
    }),
    // Редактировать программы у застрахованных
    editInsuredsPrograms: builder.mutation<
      EditInsuredsProgramsResponse,
      EditInsuredsProgramsPayload
    >({
      query: (payload) => ({
        url: 'actions/edit_insureds_programs',
        method: 'POST',
        body: getFormData(payload),
      }),
      invalidatesTags: [{ type: 'Actions', id: 'LIST' }],
    }),
    // Редактировать застрахованного
    editInsured: builder.mutation<EditInsuredsResponse, EditInsuredsPayload>({
      query: (payload) => ({
        url: 'actions/edit_insured',
        method: 'POST',
        body: getFormData(payload),
      }),
      invalidatesTags: [{ type: 'Actions', id: 'LIST' }],
    }),
    // Экспорт в XLSX
    export: builder.mutation<ExportResponse, ExportPayload>({
      query: (payload) => ({
        url: 'insureds/export',
        method: 'POST',
        body: getFormData(payload),
      }),
    }),
    // Список запросов
    actionsList: builder.query<ActionsListResponse, ActionsListPayload>({
      query: (payload) => ({
        url: 'actions/list',
        method: 'POST',
        body: getFormData(payload),
      }),
      providesTags: (result) => [{ type: 'Actions', id: 'LIST' }],
    }),
    // Отправка запрос
    sendActions: builder.mutation<SendActionsResponse, SendActionsPayload>({
      query: (payload) => ({
        url: 'actions/send',
        method: 'POST',
        body: getFormData(payload),
      }),
      invalidatesTags: [{ type: 'Actions', id: 'LIST' }],
    }),
    // Удаление запроса
    deleteAction: builder.mutation<DeleteActionResponse, DeleteActionPayload>({
      query: (payload) => ({
        url: 'actions/delete',
        method: 'POST',
        body: getFormData(payload),
      }),
      invalidatesTags: [{ type: 'Actions', id: 'LIST' }],
    }),
    // Скачать файл запроса
    actionDownload: builder.mutation<
      ActionDownloadResponse,
      ActionDownloadPayload
    >({
      query: (payload) => ({
        url: 'actions/download',
        method: 'POST',
        body: getFormData(payload),
      }),
    }),
    sendEmailDoc: builder.mutation<SendEmailDocResponse, SendEmailDocPayload>({
      query: (payload) => ({
        url: 'actions/send_email_docs',
        method: 'POST',
        body: getFormData(payload),
      }),
    }),
  }),
});

export const {
  useAddInsuredsMutation,
  useDeleteInsuredsMutation,
  useEditInsuredsProgramsMutation,
  useEditInsuredMutation,
  useExportMutation,
  useActionsListQuery,
  useSendActionsMutation,
  useDeleteActionMutation,
  useActionDownloadMutation,
  useSendEmailDocMutation,
} = actionsApi;
