import classNames from 'classnames';
import React, { FC } from 'react';
import styles from './Burger.module.sass';

interface BurgerProps extends React.ComponentPropsWithoutRef<'div'> {
  isActive?: boolean;
}

const Burger: FC<BurgerProps> = ({ isActive, className, ...rest }) => {
  return (
    <div
      className={classNames(styles.burger, className, {
        [styles['burger--active']]: isActive,
      })}
      {...rest}
    >
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default Burger;
