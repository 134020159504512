import React from 'react';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import useAppSelector from '../../../hooks/useAppSelector';
import { toggleFetchErrorPopup } from '../../../state/slices/appSlice';
import PopupLayout from '../PopupLayout/PopupLayout';
import errorImage from '../../../assets/images/error2.png';
import styles from './FetchErrorPopup.module.sass';

const FetchErrorPopup = () => {
  const dispatch = useAppDispatch();
  const isActive = useAppSelector((state) => state.app.isFetchErorPopupActive);

  const handleClose = () => dispatch(toggleFetchErrorPopup(false));

  return (
    <PopupLayout
      className={styles.popup}
      isActive={isActive}
      onClose={handleClose}
      title='HR-портал недоступен'
      subtitle={
        <>
          В настоящее время портал недоступен. Его работа восстановится в
          ближайшее время. При необходимости направьте сообщение на{' '}
          <a href='mailto:dms@kaplife.ru' className='link'>
            dms@kaplife.ru
          </a>
        </>
      }
    >
      <div className={styles.img_wrap}>
        <img src={errorImage} alt='error' />
      </div>
    </PopupLayout>
  );
};

export default FetchErrorPopup;
