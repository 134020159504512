import { configureStore } from '@reduxjs/toolkit';
import rtkQueryErrorLogger from '../middlewares/rtkQueryErrorLogger';
import { actionsApi } from '../services/actionsApi/actionsApi';
import { authApi } from '../services/authApi/authApi';
import { contractsApi } from '../services/contractsApi/contractsApi';
import { dadataApi } from '../services/dadataApi/dadataApi';
import { eventsApi } from '../services/eventsApi/eventsApi';
import { mainApi } from '../services/mainApi/mainApi';
import { personApi } from '../services/personApi/personApi';
import { reportsApi } from '../services/reportsApi/reportsApi';
import appSlice from './slices/appSlice';

const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [personApi.reducerPath]: personApi.reducer,
    [eventsApi.reducerPath]: eventsApi.reducer,
    [contractsApi.reducerPath]: contractsApi.reducer,
    [mainApi.reducerPath]: mainApi.reducer,
    [dadataApi.reducerPath]: dadataApi.reducer,
    [actionsApi.reducerPath]: actionsApi.reducer,
    [reportsApi.reducerPath]: reportsApi.reducer,
    app: appSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      personApi.middleware,
      eventsApi.middleware,
      contractsApi.middleware,
      mainApi.middleware,
      dadataApi.middleware,
      actionsApi.middleware,
      reportsApi.middleware,
      rtkQueryErrorLogger
    ),
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
