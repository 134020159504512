import React, { FC, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { usePersonInfoQuery } from '../../../services/personApi/personApi';
import { detectBase64ImageType } from '../../../utils/detectBase64ImageType';
import {
  getAuthTokenFromCookie,
  getHrIdFromCookie,
} from '../../../utils/getToken';
import PopupLayout from '../PopupLayout/PopupLayout';
import { ReactComponent as EmailSvg } from '../../../assets/images/icons/email.svg';
import { ReactComponent as PhoneSvg } from '../../../assets/images/icons/phone.svg';
import styles from './ConfimPersonDataPopup.module.sass';
import Button from '../../ui/Button/Button';
import { Link } from 'react-router-dom';

const ConfimPersonDataPopup: FC = () => {
  const [isActive, setIsActive] = useState(false);

  // Получение данных
  const { data: fetchedPersonData } = usePersonInfoQuery({
    HR_ID: getHrIdFromCookie(),
    Token: getAuthTokenFromCookie(),
  });

  // Если данные не подтверждены, то открываем попап
  useEffect(() => {
    if (localStorage.getItem('isProfileDataConfirmed') !== 'true')
      setIsActive(true);
  }, []);

  const handleClose = () => {
    localStorage.setItem('isProfileDataConfirmed', 'true');
    setIsActive(false);
  };

  if (!isActive) return <></>;

  return (
    <PopupLayout
      isActive={isActive}
      onClose={handleClose}
      title={
        <>
          Приветствуем!
          <br /> Проверьте данные своего профиля.
        </>
      }
    >
      {fetchedPersonData?.photo ? (
        <div
          className={styles.photo}
          style={{
            backgroundImage: `url(data:image/${detectBase64ImageType(
              fetchedPersonData.photo
            )};base64,${fetchedPersonData.photo})`,
          }}
        ></div>
      ) : (
        <div className={styles.photo_wrap}>
          <Skeleton className={styles.photo} />
        </div>
      )}
      {fetchedPersonData?.name ? (
        <div className={styles.name}>{fetchedPersonData.name}</div>
      ) : (
        <div className={styles.name}>
          <Skeleton width={150} />
        </div>
      )}
      {fetchedPersonData?.jobTitle ? (
        <div className={styles.job}>{fetchedPersonData.jobTitle}</div>
      ) : (
        <div className={styles.job}>
          <Skeleton width={150} />
        </div>
      )}
      <div className={styles.features}>
        {!!fetchedPersonData?.phone && (
          <div className={styles.features__item}>
            <PhoneSvg />
            {fetchedPersonData.phone}
          </div>
        )}
        {!!fetchedPersonData?.email && (
          <div className={styles.features__item}>
            <EmailSvg />
            {fetchedPersonData.email}
          </div>
        )}
      </div>
      <div className={styles.buttons}>
        <Button onClick={handleClose}>Все верно</Button>
        <Link to='/profile' onClick={handleClose} className='link fs14'>
          Перейти в профиль
        </Link>
      </div>
    </PopupLayout>
  );
};

export default ConfimPersonDataPopup;
