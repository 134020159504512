import classNames from 'classnames';
import { FC, useEffect } from 'react';
import { useLocation } from 'react-router';
import { menuLinks } from '../../../consts';
import Menu from '../Menu/Menu';
import styles from './MobileMenu.module.sass';

interface MobileMenuProps {
  isActive?: boolean;
  isNewsFetching?: boolean;
  onClose?: () => void;
}

const MobileMenu: FC<MobileMenuProps> = ({
  isActive,
  isNewsFetching,
  onClose = () => {},
}) => {
  const location = useLocation();

  useEffect(() => {
    document.body.style.overflow = isActive ? 'hidden' : '';
    return () => {
      document.body.style.overflow = '';
    };
  }, [isActive]);

  useEffect(() => {
    onClose();
  }, [location.pathname, onClose]);

  return (
    <div
      className={classNames(styles.menu, { [styles.active]: isActive })}
      onClick={onClose}
    >
      <Menu
        links={menuLinks}
        className={styles.menu__content}
        onClick={(e) => e.stopPropagation()}
        isNewsFetching={isNewsFetching}
      />
    </div>
  );
};

export default MobileMenu;
