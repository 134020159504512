import { format } from 'date-fns';
import { GetEventsListResponse } from '../../../services/eventsApi/eventsApi.interface';
import { Notification } from './NotificationList.interface';

export const formatFetchedNotifications = (
  fetchedNotifications: GetEventsListResponse
): Notification[] => {
  // Настройки уведомлений из LS
  const LSEventsOptions: { [key: string]: boolean } = JSON.parse(
    localStorage.getItem('eventsOptions') || '{}'
  );

  const formated: Notification[] | undefined = fetchedNotifications.list?.map(
    (notification) => ({
      id: notification.id,
      dateSource: new Date(notification.date),
      date: format(new Date(notification.date), 'dd.MM.yyyy'),
      isReaden: notification.state === 'READ',
      number: notification.contractNum
        ? `${notification.contractNum}`
        : undefined,
      numberId: notification.contractID,
      title: notification.text,
      url: notification.contractID
        ? `/contracts/${notification.contractID}`
        : undefined,
      category:
        notification.type === 'Report'
          ? 'Отчеты'
          : notification.type === 'Доступ'
          ? 'Профиль'
          : notification.type === 'Новый ЛПУ'
          ? 'Мед. организации'
          : notification.type === 'Организация мед. помощи'
          ? 'Договоры'
          : notification.type === 'Прочее'
          ? 'Прочее'
          : notification.type === 'Сверка'
          ? 'Расчеты'
          : 'Прочее',
      categoryUrl:
        notification.type === 'Report'
          ? '/reports'
          : notification.type === 'Доступ'
          ? '/profile'
          : notification.type === 'Новый ЛПУ'
          ? ''
          : notification.type === 'Организация мед. помощи'
          ? '/contracts'
          : notification.type === 'Прочее'
          ? ''
          : notification.type === 'Сверка'
          ? '/calculations'
          : '',
      detailUrl: notification.id ? `/service/${notification.id}` : '',
    })
  );

  const filtered: Notification[] | undefined = formated?.filter(
    (notification) =>
      (notification.category === 'Договоры' &&
        LSEventsOptions.contracts !== false) ||
      (notification.category === 'Отчеты' &&
        LSEventsOptions.reports !== false) ||
      (notification.category === 'Профиль' &&
        LSEventsOptions.profile !== false) ||
      (notification.category === 'Расчеты' &&
        LSEventsOptions.calcs !== false) ||
      (notification.category === 'Мед. организации' &&
        LSEventsOptions.medOrg !== false) ||
      notification.category === 'Прочее'
  );

  return filtered || [];
};
