type ImageType = 'jpg' | 'png' | 'gif' | 'webp' | 'svg+xml';

export const detectBase64ImageType = (
  base64: string | undefined = ''
): ImageType => {
  if (base64.charAt(0) === '/') {
    return 'jpg';
  } else if (base64.charAt(0) === 'i') {
    return 'png';
  } else if (base64.charAt(0) === 'R') {
    return 'gif';
  } else if (base64.charAt(0) === 'U') {
    return 'webp';
  } else {
    return 'svg+xml';
  }
};
