import React, { FC } from 'react';
import styles from './AuthLayout.module.sass';
import kaplifeLogoImg from '../../assets/images/kaplife-logo.svg';
import { ReactComponent as TimeLockSvg } from '../../assets/images/icons/lock-time.svg';
import { Link } from 'react-router-dom';

interface AuthLayoutProps {
  children?: React.ReactNode;
}

const AuthLayout: FC<AuthLayoutProps> = ({ children }) => {
  return (
    <div className={styles.layout}>
      <div className={styles.layout__container}>
        <div className={styles.layout__logo_wrap}>
          <Link to='/login'>
            <img src={kaplifeLogoImg} alt='Kaplife' />
          </Link>
        </div>
        {children}
        <TimeLockSvg className={styles.layout__fixed_icon} />
      </div>
    </div>
  );
};

export default AuthLayout;
