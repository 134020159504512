import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthLayout from '../layout/AuthLayout/AuthLayout';
import ControlPanelLayout from '../layout/ControlPanelLayout/ControlPanelLayout';

export interface Route {
  path: string;
  layout: React.FC<{ children?: React.ReactNode }>;
  component: React.FC | React.LazyExoticComponent<React.FC>;
  isPrivate?: boolean;
}

const routes: Route[] = [
  {
    path: '*',
    layout: AuthLayout,
    component: () => <Navigate to='/contracts' />,
  },
  {
    path: '/login',
    layout: AuthLayout,
    component: React.lazy(() => import('../pages/Login/Login')),
  },
  {
    path: '/access',
    layout: AuthLayout,
    component: React.lazy(() => import('../pages/Access/Access')),
  },
  {
    path: '/profile',
    layout: ControlPanelLayout,
    component: React.lazy(() => import('../pages/Profile/Profile')),
    isPrivate: true,
  },
  {
    path: '/contracts',
    layout: ControlPanelLayout,
    component: React.lazy(() => import('../pages/Contracts/Contracts')),
    isPrivate: true,
  },
  {
    path: '/contracts/:contractId',
    layout: ControlPanelLayout,
    component: React.lazy(() => import('../pages/Contract/Contract')),
    isPrivate: true,
  },
  {
    path: '/contracts/:contractId/insured-list',
    layout: (props) => <ControlPanelLayout {...props} showSidebar={false} />,
    component: React.lazy(() => import('../pages/InsuredList/InsuredList')),
    isPrivate: true,
  },
  {
    path: '/contracts/:contractId/insured-list/attach',
    layout: (props) => <ControlPanelLayout {...props} showSidebar={false} />,
    component: React.lazy(
      () => import('../pages/InsuredListAttach/InsuredListAttach')
    ),
    isPrivate: true,
  },
  {
    path: '/contracts/:contractId/insured-list/edit-programs',
    layout: (props) => <ControlPanelLayout {...props} showSidebar={false} />,
    component: React.lazy(
      () => import('../pages/InsuredListEditProgram/InsuredListEditProgram')
    ),
    isPrivate: true,
  },
  {
    path: '/contracts/:contractId/insured-list/detach',
    layout: (props) => <ControlPanelLayout {...props} showSidebar={false} />,
    component: React.lazy(
      () => import('../pages/InsuredListDetach/InsuredListDetach')
    ),
    isPrivate: true,
  },
  {
    path: '/notifications',
    layout: ControlPanelLayout,
    component: React.lazy(() => import('../pages/Notifications/Notifications')),
    isPrivate: true,
  },
  {
    path: '/service',
    layout: ControlPanelLayout,
    component: React.lazy(() => import('../pages/Service/Service')),
    isPrivate: true,
  },
  {
    path: '/calculations',
    layout: (props) => <ControlPanelLayout {...props} showSidebar={false} />,
    component: React.lazy(() => import('../pages/Calculations/Calculations')),
    isPrivate: true,
  },
  {
    path: '/request-act',
    layout: (props) => <ControlPanelLayout {...props} showSidebar={false} />,
    component: React.lazy(() => import('../pages/RequestAct/RequestAct')),
    isPrivate: true,
  },
  {
    path: '/service/:requestId',
    layout: ControlPanelLayout,
    component: React.lazy(() => import('../pages/RequestChat/RequestChat')),
    isPrivate: true,
  },
  {
    path: '/reports',
    layout: ControlPanelLayout,
    component: React.lazy(() => import('../pages/Reports/Reports')),
    isPrivate: true,
  },
  {
    path: '/reports/:reportId',
    layout: (props) => <ControlPanelLayout {...props} showSidebar={false} />,
    component: React.lazy(() => import('../pages/Report/Report')),
    isPrivate: true,
  },
  {
    path: '/actions',
    layout: ControlPanelLayout,
    component: React.lazy(() => import('../pages/Actions/Actions')),
    isPrivate: true,
  },
  {
    path: '/special-offers',
    layout: ControlPanelLayout,
    component: React.lazy(() => import('../pages/SpecialOffers/SpecialOffers')),
    isPrivate: true,
  },
];

export default routes;
