import Cookies from 'js-cookie';

export const getAuthTokenFromCookie = () => {
  const token = Cookies.get('auth_token') || '';

  if (!token) window.location.href = '/login';

  return token;
};

export const getHrIdFromCookie = () => {
  const HR_ID = Cookies.get('key') || '';

  if (!HR_ID) window.location.href = '/login';

  return HR_ID;
};