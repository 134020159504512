import { FC } from 'react';
import { Navigate } from 'react-router';
import Cookies from 'js-cookie';

interface PrivateRouteProps {
  children?: React.ReactNode;
}

const PrivateRoute: FC<PrivateRouteProps> = ({ children }) => {
  const token = Cookies.get('auth_token');
  const isAuthorized = token ? true : false;

  return isAuthorized ? <>{children}</> : <Navigate to='/login' />;
};

export default PrivateRoute;
