import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiUrl } from '../../consts';
import { getFormData } from '../../utils/getFormData';
import {
  AccessRecoveryPayload,
  AccessRecoveryResponse,
  LoginPayload,
  LoginResponse,
  CheckTokenPayload,
  CheckTokenResponse,
} from './authApi.interface';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl,
  }),
  endpoints: (builder) => ({
    // Авторизация
    login: builder.mutation<LoginResponse, LoginPayload>({
      query: (payload) => ({
        url: 'auth/login',
        method: 'POST',
        body: getFormData(payload),
      }),
    }),
    // Получение/восстановление доступа
    accessRecovery: builder.mutation<
      AccessRecoveryResponse,
      AccessRecoveryPayload
    >({
      query: (payload) => ({
        url: 'auth/access_recovery',
        method: 'POST',
        body: getFormData(payload),
      }),
    }),
    // Проверка токена
    checkToken: builder.query<
      CheckTokenResponse,
      CheckTokenPayload
    >({
      query: (payload) => ({
        url: 'auth/check_token',
        method: 'POST',
        body: getFormData(payload),
      }),
    }),
  }),
});

export const { useAccessRecoveryMutation, useLoginMutation, useCheckTokenQuery } = authApi;
