import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  GetAddressPayload,
  GetAddressResponse,
  GetCompaniesByInnPayload,
  GetCompaniesByInnResponse,
} from './dadataApi.interface';

export const dadataApi = createApi({
  reducerPath: 'dadataApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '',
    prepareHeaders: (headers) => {
      headers.set(
        'Authorization',
        `Token ${process.env.REACT_APP_DADATA_TOKEN}`
      );
      headers.set('X-Secret', process.env.REACT_APP_DADATA_SECRET || '');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    // Организации по ИНН
    getCompaniesByInn: builder.query<
      GetCompaniesByInnResponse,
      GetCompaniesByInnPayload
    >({
      query: (payload) => ({
        url: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party',
        method: 'POST',
        body: payload,
      }),
    }),
    // Подсказки адресов
    getAddress: builder.query<GetAddressResponse, GetAddressPayload>({
      query: (payload) => ({
        url: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const { useGetCompaniesByInnQuery, useGetAddressQuery } = dadataApi;
