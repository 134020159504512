import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiUrl } from '../../consts';
import { getFormData } from '../../utils/getFormData';
import {
  AddMedOfficePayload,
  AddMedOfficeResponse,
  GetContractProgramsPayload,
  GetContractProgramsResponse,
  GetContractsListPayload,
  GetContractsListResponse,
  GetGrafPremiumPayload,
  GetGrafPremiumResponse,
  GetInsuredsListPayload,
  GetInsuredsListResponse,
  GetRequestEventsPayload,
  GetRequestEventsResponse,
  GetRequestThemesResponse,
  GetRequestThemesPayload,
  RequestHistoryPayload,
  RequestHistoryResponse,
  RevisePayload,
  ReviseResponse,
  SendRequestPayload,
  SendRequestResponse,
  GetPolicyResponse,
  GetPolicyPayload,
} from './contractsApi.interface';

export const contractsApi = createApi({
  reducerPath: 'contractsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl,
  }),
  tagTypes: ['Request'],
  endpoints: (builder) => ({
    // Список уведомлений
    getContractsList: builder.query<
      GetContractsListResponse,
      GetContractsListPayload
    >({
      query: (payload) => ({
        url: 'contracts/list',
        method: 'POST',
        body: getFormData(payload),
      }),
    }),
    // Программы договора / программы
    getContractPrograms: builder.query<
      GetContractProgramsResponse,
      GetContractProgramsPayload
    >({
      query: (payload) => ({
        url: 'contracts/programs',
        method: 'POST',
        body: getFormData(payload),
      }),
    }),
    // Получение тем запроса
    getRequestThemes: builder.query<
      GetRequestThemesResponse,
      GetRequestThemesPayload
    >({
      query: (payload) => ({
        url: 'contracts/request_themes',
        method: 'POST',
        body: getFormData(payload),
      }),
    }),
    // Отправка запроса
    sendRequest: builder.mutation<SendRequestResponse, SendRequestPayload>({
      query: (payload) => ({
        url: 'contracts/request',
        method: 'POST',
        body: getFormData(payload),
      }),
      invalidatesTags: ['Request'],
    }),
    // История запросов
    requestHistory: builder.query<
      RequestHistoryResponse,
      RequestHistoryPayload
    >({
      query: (payload) => ({
        url: 'contracts/request_history',
        method: 'POST',
        body: getFormData(payload),
      }),
      providesTags: ['Request'],
    }),
    // Расчеты
    getGrafPremium: builder.query<
      GetGrafPremiumResponse,
      GetGrafPremiumPayload
    >({
      query: (payload) => ({
        url: 'contracts/get_graf_premium',
        method: 'POST',
        body: getFormData(payload),
      }),
    }),
    // Сверка расчетов
    revise: builder.mutation<ReviseResponse, RevisePayload>({
      query: (payload) => ({
        url: 'contracts/revise',
        method: 'POST',
        body: getFormData(payload),
      }),
    }),
    // Добавить мед организацию
    addMedOffice: builder.mutation<AddMedOfficeResponse, AddMedOfficePayload>({
      query: (payload) => ({
        url: 'contracts/add_med_office',
        method: 'POST',
        body: getFormData(payload),
      }),
    }),
    // Список застрахованных
    getInsuredsList: builder.query<
      GetInsuredsListResponse,
      GetInsuredsListPayload
    >({
      query: (payload) => ({
        url: 'contracts/insureds_list',
        method: 'POST',
        body: getFormData(payload),
      }),
    }),
    // История запросов (Детальная)
    getRequestEvents: builder.query<
      GetRequestEventsResponse,
      GetRequestEventsPayload
    >({
      query: (payload) => ({
        url: 'contracts/request_events',
        method: 'POST',
        body: getFormData(payload),
      }),
      providesTags: ['Request'],
    }),
    // Получение электронного полиса
    getPolicy: builder.mutation<GetPolicyResponse, GetPolicyPayload>({
      query: (payload) => ({
        url: 'contracts/print_policy',
        method: 'POST',
        body: getFormData(payload),
      }),
    }),
  }),
});

export const {
  useGetContractsListQuery,
  useGetContractProgramsQuery,
  useLazyGetContractProgramsQuery,
  useGetRequestThemesQuery,
  useSendRequestMutation,
  useGetGrafPremiumQuery,
  useReviseMutation,
  useRequestHistoryQuery,
  useAddMedOfficeMutation,
  useGetInsuredsListQuery,
  useLazyGetInsuredsListQuery,
  useGetRequestEventsQuery,
  useGetPolicyMutation,
} = contractsApi;
