import { MenuLink } from './common/components/Menu/Menu';
import { ReactComponent as DocumentTickSvg } from './assets/images/icons/document-tick.svg';
import { ReactComponent as MailboxSvg } from './assets/images/icons/mailbox.svg';
import { ReactComponent as StatsReportSvg } from './assets/images/icons/stats-report.svg';
import { ReactComponent as CalcSvg } from './assets/images/icons/calculate.svg';
import { ReactComponent as DocumentSvg } from './assets/images/icons/document.svg';
import { ReactComponent as NoteSvg } from './assets/images/icons/note.svg';

// API URL
export const apiUrl = 'https://stage-hr-dms-backend.kaplife.ru'; //stage
// export const apiUrl = 'https://dev-hr-dms-backend.kaplife.ru'; //dev
// export const apiUrl = 'https://hr-dms-backend.kaplife.ru'; //prod

// export const apiUrl = "https://localhost:5001" //local

// Сообщения об ошибках
export const errorMessages = {
  required: 'Обязательное поле',
  invalidPhone: 'Некорректный номер',
  invalidEmail: 'Некорректный email',
  invalidInn: 'Некорректный ИНН',
  invalidFullName: 'Некорректное ФИО',
};

// Ссылки в меню
export const menuLinks: MenuLink[] = [
  {
    text: 'Договоры',
    to: '/contracts',
    icon: <DocumentTickSvg />,
    isNotification: true,
  },
  {
    text: 'Изменения списка ЗЛ',
    to: '/actions',
    icon: <MailboxSvg />,
  },
  {
    text: 'История запросов',
    to: '/service',
    icon: <StatsReportSvg />,
  },
  {
    text: 'Расчеты',
    to: '/calculations',
    icon: <CalcSvg />,
  },
  {
    text: 'Отчеты',
    to: '/reports',
    icon: <DocumentSvg />,
  },
  {
    text: 'Спецпредложения',
    to: '/special-offers',
    icon: <NoteSvg />,
  },
];
