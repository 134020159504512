import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiUrl } from '../../consts';
import { getFormData } from '../../utils/getFormData';
import {
  DownloadZipPayload,
  DownloadZipResponse,
  GetFilePayload,
  GetFileResponse,
  GetMedServicesPayload,
  GetMedServicesResponse,
  GetPerformersPayload,
  GetPerformersResponse,
} from './mainApi.interface';

export const mainApi = createApi({
  reducerPath: 'mainApi',
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl,
  }),
  endpoints: (builder) => ({
    // Получение ссылки на файл
    getFile: builder.mutation<GetFileResponse, GetFilePayload>({
      query: (payload) => ({
        url: 'main/get_file',
        method: 'POST',
        body: getFormData(payload),
      }),
    }),
    // Скачать файлы архивом
    downloadZip: builder.mutation<DownloadZipResponse, DownloadZipPayload>({
      query: (payload) => ({
        url: 'main/download_zip',
        method: 'POST',
        body: getFormData(payload),
      }),
    }),
    // Получение куратора, врада, медпульта
    getPerformers: builder.query<GetPerformersResponse, GetPerformersPayload>({
      query: (payload) => ({
        url: 'main/performers',
        method: 'POST',
        body: getFormData(payload),
      }),
    }),
    // Получение видов помощи
    getMedServices: builder.query<
      GetMedServicesResponse,
      GetMedServicesPayload
    >({
      query: (payload) => ({
        url: 'main/med_services',
        method: 'POST',
        body: getFormData(payload),
      }),
    }),
  }),
});

export const {
  useDownloadZipMutation,
  useGetFileMutation,
  useGetPerformersQuery,
  useGetMedServicesQuery,
} = mainApi;
