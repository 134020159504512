import React, { FC, useCallback, useState } from 'react';
import styles from './ControlPanelHeader.module.sass';
import kaplifeLogoImg from '../../../assets/images/kaplife-logo.svg';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import UserPreview, { UserAction } from '../UserPreview/UserPreview';
import Burger from '../../ui/Burger/Burger';
import { ReactComponent as RingSvg } from '../../../assets/images/icons/ring.svg';
import Badge from '../../ui/Badge/Badge';
import MobileMenu from '../MobileMenu/MobileMenu';
import { usePersonInfoQuery } from '../../../services/personApi/personApi';
import {
  getAuthTokenFromCookie,
  getHrIdFromCookie,
} from '../../../utils/getToken';
import { useGetEventsListQuery } from '../../../services/eventsApi/eventsApi';
import { formatFetchedNotifications } from '../NotificationList/NotificationList.utils';

interface ControlPanelHeaderProps {
  isNewsFetching?: boolean;
  userActions?: UserAction[];
}

const ControlPanelHeader: FC<ControlPanelHeaderProps> = ({
  isNewsFetching,
  userActions,
}) => {
  const { data: fetchedNotifications } = useGetEventsListQuery({
    HR_ID: getHrIdFromCookie(),
    Token: getAuthTokenFromCookie(),
  });
  const { data: userInfo, isLoading: isUserFetching } = usePersonInfoQuery({
    HR_ID: getHrIdFromCookie(),
    Token: getAuthTokenFromCookie(),
  });
  const [isMenuActive, setIsMenuActive] = useState(false);

  const notifications = fetchedNotifications
    ? formatFetchedNotifications(fetchedNotifications)
    : [];
  const unreadAmount = notifications.filter((n) => !n.isReaden).length;

  const closeMenu = useCallback(() => setIsMenuActive(false), []);

  return (
    <header className={styles.header}>
      <div className={styles.header__container}>
        <div className={styles.header__inner}>
          <Burger
            isActive={isMenuActive}
            onClick={() => setIsMenuActive(!isMenuActive)}
            className={styles.header__burger}
          />
          <Link to='/contracts' className={styles.header__logo}>
            <img src={kaplifeLogoImg} alt='КАПИТАЛ LIFE' />
          </Link>
          <div className={styles.header__date}>
            {format(new Date(), 'dd MMMM', { locale: ru })},{' '}
            <span className={styles.header__weekday}>
              {format(new Date(), 'EEEE', { locale: ru })}
            </span>
          </div>
          <div className={styles.header__right}>
            <div className={styles.header__action_list}>
              <Link to='/notifications' className={styles.header__action_item}>
                <RingSvg className={styles.header__action_icon} />
                {!!unreadAmount && <Badge>+{unreadAmount}</Badge>}
              </Link>
            </div>
            <UserPreview
              link='/profile'
              image={userInfo?.photo || undefined}
              title={userInfo?.name || undefined}
              description={userInfo?.company || undefined}
              loading={isUserFetching}
              actions={userActions}
            />
          </div>
        </div>
      </div>

      <MobileMenu
        isActive={isMenuActive}
        isNewsFetching={isNewsFetching}
        onClose={closeMenu}
      />
    </header>
  );
};

export default ControlPanelHeader;
