import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import FetchErrorPopup from './common/components/FetchErrorPopup/FetchErrorPopup';
import PrivateRoute from './common/components/PrivateRoute/PrivateRoute';
import ScrollToTop from './common/components/ScrollToTop/ScrollToTop';
import routes from './routes/routes';
import { setVhVariable } from './utils/setVhVariable';

const App = () => {
  setVhVariable();

  useEffect(() => {
    window.addEventListener('resize', setVhVariable);
    return () => {
      window.removeEventListener('resize', setVhVariable);
    };
  });

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {routes.map(
          ({ path, component: Component, layout: Layout, isPrivate }) => (
            <Route
              path={path}
              key={path}
              element={
                isPrivate ? (
                  <PrivateRoute>
                    <Layout>
                      <React.Suspense fallback={<></>}>
                        <Component />
                      </React.Suspense>
                    </Layout>
                  </PrivateRoute>
                ) : (
                  <Layout>
                    <React.Suspense fallback={<></>}>
                      <Component />
                    </React.Suspense>
                  </Layout>
                )
              }
            />
          )
        )}
      </Routes>
      <FetchErrorPopup />
    </BrowserRouter>
  );
};

export default App;
