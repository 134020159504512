import React, { FC } from 'react';
import ControlPanelHeader from '../../common/components/ControlPanelHeader/ControlPanelHeader';
import Menu from '../../common/components/Menu/Menu';
import { menuLinks } from '../../consts';
import styles from './ControlPanelLayout.module.sass';
import { ReactComponent as PasswordSvg } from '../../assets/images/icons/password.svg';
import { ReactComponent as GearSvg } from '../../assets/images/icons/gear.svg';
import { ReactComponent as ExitSvg } from '../../assets/images/icons/exit.svg';
import { useNavigate } from 'react-router';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import {
  getAuthTokenFromCookie,
  getHrIdFromCookie,
} from '../../utils/getToken';
import { useCheckTokenQuery } from '../../services/authApi/authApi';
import { useActionsListQuery } from '../../services/actionsApi/actionsApi';
import { toggleUnsendedActionsPopup } from '../../state/slices/appSlice';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import UnsendedActionsAttentionPopup from '../../common/components/UnsendedActionsAttentionPopup/UnsendedActionsAttentionPopup';
import ConfimPersonDataPopup from '../../common/components/ConfimPersonDataPopup/ConfimPersonDataPopup';

interface ControlPanelLayoutProps {
  children?: React.ReactNode;
  showSidebar?: boolean;
}

const ControlPanelLayout: FC<ControlPanelLayoutProps> = ({
  children,
  showSidebar = true,
}) => {
  const dispatch = useAppDispatch();
  const { data: tokenStatus } = useCheckTokenQuery({
    Token: getAuthTokenFromCookie(),
  });
  // Получение действий
  const { data: fetchedActions } = useActionsListQuery({
    HR_ID: getHrIdFromCookie(),
    Token: getAuthTokenFromCookie(),
  });
  if (tokenStatus && !tokenStatus.status) {
    window.location.href = '/login';
    Cookies.remove('auth_token');
  }

  const navigate = useNavigate();
  const isNewsFetching = false;

  const userActions = [
    {
      icon: <PasswordSvg />,
      text: 'Сменить пароль',
      onClick: () => navigate('/access'),
    },
    {
      icon: <GearSvg />,
      text: 'Настройки',
      onClick: () => navigate('/profile'),
    },
    {
      icon: <ExitSvg />,
      text: 'Выйти',
      onClick: () => {
        if (fetchedActions?.notSended.length) {
          dispatch(toggleUnsendedActionsPopup(true));
        } else {
          Cookies.remove('auth_token');
          Cookies.remove('hr_id');
          navigate('/login');
        }
      },
    },
  ];

  return (
    <div
      className={classNames(styles.layout, {
        [styles['layout--no_sidebar']]: !showSidebar,
      })}
    >
      <ControlPanelHeader
        isNewsFetching={isNewsFetching}
        userActions={userActions}
      />
      <div className={styles.layout__container}>
        <div className={styles.layout__inner}>
          {showSidebar && (
            <div className={styles.layout__sidebar}>
              <Menu
                links={menuLinks}
                isNewsFetching={isNewsFetching}
                className={styles.layout__menu}
              />
            </div>
          )}
          <div className={styles.layout__content}>{children}</div>
        </div>
      </div>
      {/* Попап неотправленных действий */}
      <UnsendedActionsAttentionPopup />
      {/* Попап подтверждения данных профиля */}
      <ConfimPersonDataPopup />
    </div>
  );
};

export default ControlPanelLayout;
