import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiUrl } from '../../consts';
import { getFormData } from '../../utils/getFormData';
import {
  DeleteEventPayload,
  DeleteEventResponse,
  GetEventsListPayload,
  GetEventsListResponse,
} from './eventsApi.interface';

export const eventsApi = createApi({
  reducerPath: 'eventsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl,
  }),
  tagTypes: ['Events'],
  endpoints: (builder) => ({
    // Список уведомлений
    getEventsList: builder.query<GetEventsListResponse, GetEventsListPayload>({
      query: (payload) => ({
        url: 'events/list',
        method: 'POST',
        body: getFormData(payload),
      }),
      providesTags: (result) =>
        result?.list
          ? [
              ...result.list.map(({ id }) => ({ type: 'Events', id } as const)),
              { type: 'Events', id: 'LIST' },
            ]
          : [{ type: 'Events', id: 'LIST' }],
    }),
    // Сделать уведомление прочитанным
    deleteEvent: builder.mutation<DeleteEventResponse, DeleteEventPayload>({
      query: (payload) => ({
        url: 'events/delete',
        method: 'POST',
        body: getFormData(payload),
      }),
      invalidatesTags: ['Events'],
    }),
  }),
});

export const { useGetEventsListQuery, useDeleteEventMutation } = eventsApi;
