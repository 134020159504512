import React from 'react';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import useAppSelector from '../../../hooks/useAppSelector';
import { toggleUnsendedActionsPopup } from '../../../state/slices/appSlice';
import PopupLayout from '../PopupLayout/PopupLayout';
import styles from './UnsendedActionsAttentionPopup.module.sass';
import bellImage from '../../../assets/images/bell.png';
import Button from '../../ui/Button/Button';
import { useNavigate } from 'react-router';
import { useActionsListQuery } from '../../../services/actionsApi/actionsApi';
import {
  getAuthTokenFromCookie,
  getHrIdFromCookie,
} from '../../../utils/getToken';
import Cookies from 'js-cookie';
import { usePersonInfoQuery } from '../../../services/personApi/personApi';

const UnsendedActionsAttentionPopup = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isActive = useAppSelector(
    (state) => state.app.isUnsendedActionsPopupActive
  );
  // Получение действий
  const { data: fetchedActions } = useActionsListQuery({
    HR_ID: getHrIdFromCookie(),
    Token: getAuthTokenFromCookie(),
  });
  // Данные о пользователе
  const { data: fetchedPerson } = usePersonInfoQuery({
    HR_ID: getHrIdFromCookie(),
    Token: getAuthTokenFromCookie(),
  });
  const nameArr = fetchedPerson?.name?.split(' ') || [];

  const handleClose = () => dispatch(toggleUnsendedActionsPopup(false));

  return (
    <PopupLayout
      className={styles.popup}
      isActive={isActive}
      onClose={handleClose}
      title={`${nameArr[1] || ''} ${
        nameArr[2] || ''
      }, у Вас есть неотправленные запросы.`}
    >
      <div className={styles.image_wrap}>
        <div className={styles.image}>
          <img src={bellImage} alt='' />{' '}
          <div className={styles.image__count}>
            {fetchedActions?.notSended.length}
          </div>
        </div>
      </div>
      <div className={styles.buttons}>
        <Button
          onClick={() => {
            navigate('/actions');
            handleClose();
          }}
        >
          Перейти к отправке
        </Button>
        <div
          className='link fs14'
          onClick={() => {
            Cookies.remove('auth_token');
            Cookies.remove('hr_id');
            handleClose();
            navigate('/login');
          }}
        >
          Выйти
        </div>
      </div>
    </PopupLayout>
  );
};

export default UnsendedActionsAttentionPopup;
