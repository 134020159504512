import classNames from 'classnames';
import React, { FC, useCallback, useEffect } from 'react';
import styles from './PopupLayout.module.sass';
import { ReactComponent as CrossSvg } from '../../../assets/images/icons/cross.svg';

interface PopupLayoutProps {
  isActive: boolean;
  onClose: () => void;
  title?: string | JSX.Element;
  subtitle?: string | React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  innerClassName?: string;
  titleClassName?: string;
  crossClassName?: string;
  subtitleClassName?: string;
  headClassName?: string;
}

const PopupLayout: FC<PopupLayoutProps> = ({
  isActive,
  className,
  innerClassName,
  titleClassName,
  crossClassName,
  subtitleClassName,
  headClassName,
  title,
  subtitle,
  children,
  onClose = () => {},
}) => {
  // Закрытие попапа при клике снаружи
  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      const target = e.target;
      if (
        target instanceof Element &&
        target.closest(`.${styles.popup}`) &&
        !target.closest(`.${styles.popup__main}`)
      ) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.body.style.overflow = isActive ? 'hidden' : '';
    return () => {
      document.body.style.overflow = '';
    };
  }, [isActive]);

  useEffect(() => {
    document.body.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.body.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div
      className={classNames(styles.popup, {
        [styles['active']]: isActive,
      })}
    >
      <div className={classNames(styles.popup__inner, innerClassName)}>
        <div className={classNames(styles.popup__main, className)}>
          <div
            className={classNames(styles.popup__cross, crossClassName)}
            onClick={onClose}
          >
            <CrossSvg />
          </div>
          {(title || subtitle) && (
            <div className={classNames(styles.popup__head, headClassName)}>
              {title && (
                <div
                  className={classNames(styles.popup__title, titleClassName)}
                >
                  {title}
                </div>
              )}
              {subtitle && (
                <div
                  className={classNames(
                    styles.popup__subtitle,
                    subtitleClassName
                  )}
                >
                  {subtitle}
                </div>
              )}
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export default PopupLayout;
