import {
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
} from '@reduxjs/toolkit';
import { toggleFetchErrorPopup } from '../state/slices/appSlice';

const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (dispatch) => (action) => {
    // Отлов всех ошибок в запросах
    if (isRejectedWithValue(action)) {

      // При ошибке, диспатчим открытие попапа с ошибкой
      dispatch(toggleFetchErrorPopup(true));
    }

    return dispatch(action);
  };

export default rtkQueryErrorLogger;
