import React, { FC } from 'react';
import styles from './Menu.module.sass';
import { To } from 'react-router';
import { NavLink } from 'react-router-dom';
import { ReactComponent as RingSvg } from '../../../assets/images/icons/ring.svg';
import { ReactComponent as StatCircleSvg } from '../../../assets/images/icons/stat-circle.svg';
import classNames from 'classnames';
import { useActionsListQuery } from '../../../services/actionsApi/actionsApi';
import {
  getAuthTokenFromCookie,
  getHrIdFromCookie,
} from '../../../utils/getToken';

export interface MenuLink {
  text: string;
  icon: React.ReactNode;
  to: To;
  isNotification?: boolean;
  time?: string;
}

interface MenuProps extends React.ComponentPropsWithoutRef<'div'> {
  links: MenuLink[];
  isNewsFetching?: boolean;
}

const Menu: FC<MenuProps> = ({ links, className, isNewsFetching, ...rest }) => {
  // Получение действий
  const { data: fetchedActions } = useActionsListQuery({
    HR_ID: getHrIdFromCookie(),
    Token: getAuthTokenFromCookie(),
  });

  return (
    <div className={classNames(styles.menu, className)} {...rest}>
      <div className={styles.menu__list}>
        {links?.map((link, index) => (
          <NavLink
            to={link.to}
            className={({ isActive }) =>
              classNames(styles.menu__item, { [styles.active]: isActive })
            }
            key={index}
          >
            <div className={styles.menu__item_icon}>{link.icon}</div>
            <div className={styles.menu__item_text}>{link.text}</div>
            {link.text === 'Изменения списка ЗЛ' && !!fetchedActions?.notSended.length ? (
              <RingSvg className={styles.menu__item_notification} />
            ) : null}
            {link.time && (
              <div className={styles.menu__item_time}>
                <StatCircleSvg />
                {link.time}
              </div>
            )}
          </NavLink>
        ))}
      </div>
      {/* <div className={styles.menu__bottom}>
        <Link to='/news' className={styles.menu__bottom_link}>
          Новости <ArrowRightSvg />
        </Link>
        <Link to='/news' className={styles.menu__footer}>
          {isNewsFetching ? (
            <Skeleton
              containerClassName={styles.menu__footer_title}
              count={3}
            />
          ) : (
            <div className={styles.menu__footer_title}>
              С начала пандемии компания КАПИТАЛ LIFE выплатила клиентам более
              200 млн рублей по рискам, связанным с коронавирусом
            </div>
          )}
          {isNewsFetching ? (
            <Skeleton containerClassName={styles.menu__footer_desc} width={50} />
          ) : (
            <div className={styles.menu__footer_desc}>25.12.2021</div>
          )}
        </Link>
      </div> */}
    </div>
  );
};

export default Menu;
