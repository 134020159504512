import classNames from 'classnames';
import React, { FC } from 'react';
import styles from './Spinner.module.sass';

interface SpinnerProps extends React.ComponentPropsWithoutRef<'div'> {}

const Spinner: FC<SpinnerProps> = ({ className, ...rest }) => {
  return (
    <div className={classNames(className, styles.spinner)} {...rest}>
      <div className={styles.bar1}></div>
      <div className={styles.bar3}></div>
      <div className={styles.bar4}></div>
      <div className={styles.bar6}></div>
      <div className={styles.bar7}></div>
      <div className={styles.bar9}></div>
      <div className={styles.bar10}></div>
      <div className={styles.bar12}></div>
    </div>
  );
};

export default Spinner;
