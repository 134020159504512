import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiUrl } from '../../consts';
import { getFormData } from '../../utils/getFormData';
import {
  InfoPayload,
  InfoResponse,
  SetPersonDataPayload,
  SetPersonDataResponse,
  SetPhotoPayload,
  SetPhotoResponse,
} from './personApi.interface';

export const personApi = createApi({
  reducerPath: 'personApi',
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl,
  }),
  tagTypes: ['PersonInfo'],
  endpoints: (builder) => ({
    // Получение информации о пользователе
    personInfo: builder.query<InfoResponse, InfoPayload>({
      query: (payload) => ({
        url: 'person/info',
        method: 'POST',
        body: getFormData(payload),
      }),
      providesTags: (result) => [{ type: 'PersonInfo', id: 'PERSON' }],
    }),
    // Изменение фото пользователя
    setPhoto: builder.mutation<SetPhotoResponse, SetPhotoPayload>({
      query: (payload) => ({
        url: 'person/set_photo',
        method: 'POST',
        body: getFormData(payload),
      }),
      invalidatesTags: ['PersonInfo'],
    }),
    // Изменение данных пользователя
    setPersonData: builder.mutation<
      SetPersonDataResponse,
      SetPersonDataPayload
    >({
      query: (payload) => ({
        url: 'person/set_data',
        method: 'POST',
        body: getFormData(payload),
      }),
    }),
  }),
});

export const {
  usePersonInfoQuery,
  useSetPhotoMutation,
  useSetPersonDataMutation,
} = personApi;
