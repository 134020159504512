import {
  ReportRequestResponse,
  ReportRequestPayload,
  GetReportResponse,
  GetReportPayload,
  ReportsListPayload,
  ReportsListResponse,
  GetReportFilesResponse,
  GetReportFilesPayload,
} from './reportsApi.interface';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiUrl } from '../../consts';
import { getFormData } from '../../utils/getFormData';

export const reportsApi = createApi({
  reducerPath: 'reportsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl,
  }),
  endpoints: (builder) => ({
    // Запрос отчета
    reportRequest: builder.mutation<
      ReportRequestResponse,
      ReportRequestPayload
    >({
      query: (payload) => ({
        url: 'contracts/report_request',
        method: 'POST',
        body: getFormData(payload),
      }),
    }),
    // Список отчетов
    reportsList: builder.query<ReportsListResponse, ReportsListPayload>({
      query: (payload) => ({
        url: 'reports/list',
        method: 'POST',
        body: getFormData(payload),
      }),
    }),
    // Отчет
    getReport: builder.query<GetReportResponse, GetReportPayload>({
      query: (payload) => ({
        url: 'reports/get',
        method: 'POST',
        body: getFormData(payload),
      }),
    }),
    // Получение файла очтета
    getReportFiles: builder.mutation<
      GetReportFilesResponse,
      GetReportFilesPayload
    >({
      query: (payload) => ({
        url: 'reports/get_files',
        method: 'POST',
        body: getFormData(payload),
      }),
    }),
  }),
});

export const {
  useReportRequestMutation,
  useReportsListQuery,
  useGetReportQuery,
  useGetReportFilesMutation,
} = reportsApi;
