import classNames from 'classnames';
import React, { FC } from 'react';
import styles from './Badge.module.sass';

interface BadgeProps extends React.ComponentPropsWithoutRef<'div'> {
  children?: React.ReactNode;
}

const Badge: FC<BadgeProps> = ({ children, className }) => {
  return children ? (
    <div className={classNames(styles.badge, className)}>{children}</div>
  ) : null;
};

export default Badge;
