import React, { FC, useEffect, useState } from 'react';
import styles from './UserPreview.module.sass';
import { ReactComponent as ArrowDownSvg } from '../../../assets/images/icons/arrow-down.svg';
import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { detectBase64ImageType } from '../../../utils/detectBase64ImageType';

export interface UserAction extends React.ComponentPropsWithoutRef<'div'> {
  icon?: React.ReactNode;
  text?: string;
}

interface UserPreviewProps extends React.ComponentPropsWithoutRef<'div'> {
  image?: string;
  link?: string;
  title?: string;
  description?: string;
  loading?: boolean;
  actions?: UserAction[];
  onImageClick?: () => void;
}

const UserPreview: FC<UserPreviewProps> = ({
  image,
  link,
  title,
  description,
  loading,
  className,
  actions,
  onImageClick = () => {},
  ...rest
}) => {
  const [isMenuActive, setIsMenuActive] = useState(false);

  useEffect(() => {
    document.body.addEventListener('click', closeMenu);
    return () => document.body.removeEventListener('click', closeMenu);
  }, []);

  const closeMenu = () => setIsMenuActive(false);

  const handleTogglerClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsMenuActive(!isMenuActive);
  };

  return (
    <div
      className={classNames(styles.user, className, {
        [styles['user--menu-active']]: isMenuActive,
      })}
      {...rest}
    >
      {loading ? (
        <Skeleton className={styles.user__image} />
      ) : (
        <Link
          to={link || ''}
          className={styles.user__image}
          style={{
            backgroundImage: `url(data:image/${detectBase64ImageType(
              image
            )};base64,${image})`,
          }}
          onClick={onImageClick}
        ></Link>
      )}

      <Link to={link || ''} className={styles.user__text}>
        {loading ? (
          <Skeleton containerClassName={styles.user__title} width={100} />
        ) : (
          title && <div className={styles.user__title}>{title}</div>
        )}
        {loading ? (
          <Skeleton containerClassName={styles.user__description} width={100} />
        ) : (
          description && (
            <div className={styles.user__description}>{description}</div>
          )
        )}
      </Link>
      <div className={styles.user__toggle} onClick={handleTogglerClick}>
        <ArrowDownSvg className={styles.user__toggle_icon} />
      </div>
      {actions && (
        <div className={styles.user_menu} onClick={(e) => e.stopPropagation()}>
          {actions.map(({ icon, text, onClick = () => {}, ...rest }, index) => (
            <div
              className={styles.user_menu__item}
              key={index}
              onClick={(e) => {
                onClick(e);
                setIsMenuActive(false);
              }}
              {...rest}
            >
              <div className={styles.user_menu__item_icon}>{icon}</div>
              {text}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UserPreview;
